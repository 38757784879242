<template>
	<div class="container-fluid">
		<PageTitle :noAdd="false" />
        
        <div class="card" v-if="isList">
            <div class="card-body">
                <div class="row">
                <div class="col-md-8">
                    <h5 class="card-title">{{pageTitle}} List {{row.al_title}}</h5>
                </div>
                <div class="col-md-4">
                    <form @submit.prevent="doFilter(),doSearch()">
                    <div class="form-group mb-0">
                        <div class="input-group">
                        <input v-model="filter.search" type="text" class="form-control"
                            placeholder="Type and Press Enter" />
                        <div class="input-group-append">
                            <button class="btn btn-info" type="submit"><i class="fas fa-search"></i></button>
                        </div>
                        <button v-if="isSearch" @click="doReset(),clsSearch()" type="button" class="btn btn-danger ml-1"><i class="fa fa-times"></i>
                        </button>
                        </div>
                    </div>
                    </form>
                </div>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table product-overview table-striped">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Name Category</th>
                        <th>Total Article</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="data.data===false"><td colspan="99" class="text-center"><Loader class="mt10 mb10" /></td></tr>
                    <tr v-for="(v,k) in data.data" :key="k">
                        <td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
                        <td><span v-tooltip="v.aac_update_date ? 'Last updated '+ v.aac_update_date.dates('format2') : 'Created '+ v.aac_create_date.dates('format2')">{{ v.aac_name }}</span></td>
                        <td>{{ v.aac_total_article }}</td>
                        <td><LabelStatus :status="v[statusKey]" /></td>
                        <td>
                        <router-link 
                            :to="{name:$route.name, params:{pageSlug:v[idKey]}}"  
                            class="text-inverse icon_action act_icon" v-tooltip="'Edit'">
                            <i class="ti-marker-alt"></i>
                        </router-link> 
                        <a 
                            href="javascript:;" 
                            class="text-inverse icon_action act_icon" v-tooltip="'Change Status'"
                            @click="v.aac_total_article > 0 ? doChangeStatusCategory(k,v) : doChangeStatus(k,v)">
                            <i class="ti-settings"></i>
                        </a> 
                        <a 
                            href="javascript:;" 
                            class="text-inverse icon_action act_icon" 
                            v-tooltip="'Delete'"
                            @click="doDelete(k,v)">
                            <i class="ti-trash"></i>
                        </a>
                        </td>
                    </tr>
                    <tr v-if="notFound"><td colspan="99" class="text-center"><h4>{{notFound}}</h4></td></tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer">
                <div class="row">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                    <div class="pagination-flat float-right" v-if="data.data!==false&&data.data.length">
                    <Pagination :data="data" :limit="2" @pagination-change-page="doPaginate">
                        <span slot="prev-nav"><i class="icon-arrow-left"></i></span>
                        <span slot="next-nav"><i class="icon-arrow-right"></i></span>
                    </Pagination>
                    </div>
                </div>
                </div>
            </div>
        </div>

		<div class="card" v-else>
            <div class="card-body">
                <h5 class="card-title mb-3">{{isAdd?'Add':'Edit'}} {{pageTitle}}</h5>
                
                <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
                    <form @submit.prevent="handleSubmit(doPost)">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                        <label class="control-label">
                                            Article Category Name 
                                            <span class="text-danger mr5">*</span>
                                        </label>
                                        <input 
                                            v-model="row.aac_name"
                                            type="text" 
                                            class="form-control" 
                                            placeholder="Article Category Name" 
                                        /> 
                                        <VValidate name="Article Category Name " v-model="row.aac_name" :rules="{required : 1, min : 3}"  />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                    <label class="control-label">
                                        Status 
                                        <span class="text-danger mr5">*</span> 
                                    </label>
                                    <div>
                                        <InputRadio v-model="row[statusKey]" name="status" option="Y" />
                                        <InputRadio v-model="row[statusKey]" name="status" option="N" />
                                    </div>
                                    <small v-if="row.aac_total_article > 0" class="text-info">This category already has articles. The status of articles linked to this category will also be changed.</small>
                                    <VValidate name="Status" v-model="row[statusKey]" rules="required" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-md-2 offset-md-10">
                                <button type="submit" class="btn btn-block btn-rounded  btn-info">Submit</button>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </div>  
        </div>
    </div>
</template>

<style scoped>
.dropdown-toggle .clear {
  display: none;
}
</style>

<script>
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'

export default{
  extends:GlobalVue,
  components:{
    PageTitle,
  },
  data(){
    return {
      idKey:'aac_id',
      isSearch : false,
      statusKey : 'aac_is_active'
    }
  },
  mounted(){
    this.apiGet()
  },
  methods:{
    doSearch (){
        this.isSearch = true
    },
    clsSearch(){
        this.isSearch = false
    },
    doPost(){
      if(this.isAdd){
        this.row["aac_create_by"] = this.user.id
        this.row["aac_total_article"] = 0
        this.doSubmitCRUD();
      }
      else{
        this.row["aac_update_by"] = this.user.id
        this.doSubmitCRUD();
      }
    },
  }, 
  watch:{
    $route(){
      this.apiGet()
    }
  }
}
</script>